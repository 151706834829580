:root {

  --zc-primary-color: #8bc53f;
  --zc-primary-color-rgb: 139, 197, 63;
  --zc-primary-color-dark: #67912f;
  --zc-primary-color-light: #c5e29f;
  --zc-primary-color-lighter: #edf6e2;

  --zc-secondary-color: #516b85;
  --zc-secondary-color-rgb: 81, 107, 133;
  --zc-secondary-color-dark: #3b4e61;
  --zc-secondary-color-light: #a7b4c1;
  --zc-secondary-color-lighter: #e4e8ec;

  --zc-accent-color: #0080ff;
  --zc-accent-color-dark: #0066cc;
  --zc-accent-color-light: #b3d9ff;
  --zc-accent-color-lighter: #eef6ff;

  --zc-error-color: #ff4f4f;
  --zc-error-color-dark: #c53f3f;
  --zc-error-color-light: #ff9c9c;
  --zc-error-color-lighter: #ffdfdf;

  /* react-toastify */
  --toastify-color-success: var(--zc-primary-color);
  --toastify-color-error: var(--zc-error-color);

  /* Bootstrap */
  --bs-body-color: var(--zc-secondary-color);
  --bs-link-color: var(--zc-secondary-color);
  --bs-link-hover-color: var(--zc-secondary-color-dark);
  --bs-primary-rgb: var(--zc-primary-color-rgb);
  --bs-secondary-rgb: var(--zc-secondary-color-rgb);
  
}

/* Bootstrap */
.alert {
  --bs-alert-padding-x: .75rem;
  --bs-alert-padding-y: .75rem;
}

.nav-tabs {
  --bs-nav-tabs-link-active-color: var(--zc-secondary-color);
}

.nav-pills .nav-link.active {
  --bs-nav-pills-link-active-bg: var(--zc-secondary-color-light);
}

.pagination {
  --bs-pagination-color: var(--zc-secondary-color);
  --bs-pagination-active-bg: var(--zc-secondary-color);
  --bs-pagination-active-border-color: var(--zc-secondary-color);
  --bs-pagination-hover-color: var(--zc-secondary-color);
  --bs-pagination-hover-bg: var(--zc-secondary-color-lighter);
  --bs-pagination-hover-border-color: var(--zc-secondary-color-lighter);
  --bs-pagination-focus-bg: var(--zc-secondary-color-lighter);
  --bs-pagination-focus-color: var(--zc-secondary-color);
  --bs-pagination-disabled-color: var(--zc-secondary-color);
}

.btn-primary {
  --bs-btn-bg: var(--zc-primary-color);
  --bs-btn-border-color: var(--zc-primary-color);
  --bs-btn-hover-bg: var(--zc-primary-color-dark);
  --bs-btn-hover-border-color: var(--zc-primary-color-dark);
  --bs-btn-disabled-bg: var(--zc-primary-color-light);
  --bs-btn-disabled-border-color: var(--zc-primary-color-light);
}

.btn-secondary {
  --bs-btn-bg: var(--zc-secondary-color);
  --bs-btn-border-color: var(--zc-secondary-color);
  --bs-btn-hover-bg: var(--zc-secondary-color-dark);
  --bs-btn-hover-border-color: var(--zc-secondary-color-dark);
  --bs-btn-disabled-bg: var(--zc-secondary-color-light);
  --bs-btn-disabled-border-color: var(--zc-secondary-color-light);
}

.btn-outline-secondary {
  --bs-btn-color: var(--zc-secondary-color);
  --bs-btn-border-color: var(--zc-secondary-color);
  --bs-btn-hover-bg: var(--zc-secondary-color);
  --bs-btn-hover-border-color: var(--zc-secondary-color);
  --bs-btn-active-bg: var(--zc-secondary-color);
  --bs-btn-active-border-color: var(--zc-secondary-color);
  --bs-btn-disabled-color: var(--zc-secondary-color);
  --bs-btn-disabled-border-color: var(--zc-secondary-color);
}

.btn-danger {
  --bs-btn-bg: var(--zc-error-color);
  --bs-btn-border-color: var(--zc-error-color);
  --bs-btn-hover-bg: var(--zc-error-color-dark);
  --bs-btn-hover-border-color: var(--zc-error-color-dark);
  --bs-btn-focus-shadow-rgb: 225,83,97;
  --bs-btn-disabled-bg: var(--zc-error-color-light);
  --bs-btn-disabled-border-color: var(--zc-error-color-light);
}

/* Table */

.table {
  --bs-table-hover-color: #FFF;
  --bs-table-hover-bg: var(--zc-secondary-color);
  --bs-table-active-bg: var(--zc-primary-color) !important;
  --bs-table-active-color: #FFF;
  --bs-table-color: var(--zc-secondary-color);
}

.table-active {
  --bs-table-striped-bg: var(--bs-table-active-bg);
  --bs-table-striped-color: var(--bs-table-active-color);
}

.table-bordered>:not(caption)>* {
  border-width: var(--bs-border-width) 0;
}
.table-bordered>:not(caption)>*>* {
  border-width: 0 var(--bs-border-width);
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-color-type: #FFF;
  --bs-table-bg-type: var(--zc-secondary-color-light);
}

/* Form controls */

.form-control, .form-select, .form-control:focus, .form-select:focus {
  color: var(--zc-secondary-color);
}

.form-control::placeholder, .form-select::placeholder {
  color: var(--zc-secondary-color);
  opacity: 1; /* Firefox */
}

.form-control::-ms-input-placeholder, .form-select::-ms-input-placeholder { /* Edge 12-18 */
  color: var(--zc-secondary-color);
}

.input-group-text {
  color: #FFF;
  background-color: var(--zc-secondary-color);
  border: 1px solid var(--zc-secondary-color);
}

.input-group-text[role=button]:hover {
  background-color: var(--zc-secondary-color-dark);
  border: 1px solid var(--zc-secondary-color-dark);
}

.form-check-input:checked {
  background-color: var(--zc-primary-color);
  border-color: var(--zc-primary-color);
}

.form-switch * {
  cursor: pointer;
}

.tooltip {
  --bs-tooltip-bg: var(--zc-secondary-color);
}

/* react-select custom prefix */

.zc-select__placeholder {
  color: var(--zc-secondary-color) !important;
}

.zc-select__single-value {
  color: var(--zc-secondary-color-dark) !important;
}

.zc-select__option--is-selected {
  background-color: var(--zc-secondary-color) !important;
}

.zc-select__multi-value {
  background-color: var(--zc-secondary-color-light) !important;
  color: var(--zc-secondary-color-dark) !important;
}

.zc-select__option--is-focused {
  background-color: var(--zc-secondary-color-light) !important;
  color: var(--zc-secondary-color-dark) !important;
}

.zc-select__menu {
  width: max-content !important;
}

/* Custom styles */

.bg-zc {
  background-color: var(--zc-secondary-color) !important;
}

.bg-zc-light {
  background-color: var(--zc-secondary-color-light) !important;
}

.border-secondary-light {
  border-color: var(--zc-secondary-color-light);
}

.text-zc {
  color: var(--zc-primary-color);
}

.page-header {
  color: var(--zc-secondary-color-dark);
  font-weight: bold;
}

.section-title {
  color: var(--zc-secondary-color-dark);
  font-weight: bold;
  background-color: var(--zc-secondary-color-lighter);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  line-height: 1.5;
  margin-bottom: 0;
  font-size: 1.25rem!important;
}

.section-title.with-filter {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.section-content {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Custom date picker inside dropdown */
.custom-datepicker-dropdown {
  
  .dropdown-item:hover, .dropdown-item:active {
    background-color: white;
    color: var(--zc-secondary-color)
  }
}