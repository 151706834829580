.metrics-page .column-chart text[text-anchor="middle"] {
  transform: translateY(2px);
}

.metrics-page {


  .metric-nps {

    .promoters {
      color: var(--zc-primary-color)
    }

    .detractors {
      color: var(--zc-error-color);
    }
  }

  .google-visualization-table {
    margin-top: 1rem;

    .google-visualization-table-th {
      cursor: auto;
      background-image: none;
    }
  }

}